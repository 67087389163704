<template>
  <div>
    <h1 class="mb-m">Отчеты</h1>

    <ReclamationExternalFilterReports
      class="mb-30"
      @request="onSubmit"
      @reset="resetFilter"
    />

    <Spinner v-if="isLoading" />

    <div v-else-if="rows.length" class="column gap-m">
      <div class="d-flex gap-l">
        <VCheckbox v-model="checkboxes.table" label="Таблица" />

        <VCheckbox 
          v-if="currentReport.value !== 'metal-losses'"
          v-model="checkboxes.chart" 
          label="График" 
        />
      </div>

      <div class="between-center">
        <h3>{{ currentReport.name }}</h3>

        <div class="buttons">
          <VButton
            text="На печать"
            icon="print"
            size="sm"
            @click.native="openModal"
          />

          <download-excel
            :name="currentReport.name"
            :header="excel.header"
            :fields="excel.fields"
            :fetch="getExcelData"
          >
            <VButton
              text="Экспорт в XLS"
              icon="export"
              size="sm"
              :loading="excel.isLoading"
            />
          </download-excel>
        </div>
      </div>

      <div v-if="checkboxes.table" id="printTable">
        <ReportFilterInfo
          v-if="visibleTableFilter"
          class="mb-30"
          :title="currentReport.name"
          :date="currentFilter.date"
        />

        <AppTable
          v-if="isReportMetalLoss"
          ref="AppTable"
          :columns="currentReport.table.columns"
          :items="rows"
          :tree-children-keys="currentReport.table.treeKeys"
          is-tree-table
          :is-open-tree="isOpenTableTree"
          withTotal
        />

        <AppTable
          v-else-if="isReportStatistic"
          ref="AppTable"
          :columns="currentReport.table.columns"
          :items="rows"
          withTotal
        />

        <ReclamationExternalReportTable
          v-else
          :report-id="reportId"
          :rows="rows"
        />
      </div>

      <div v-if="checkboxes.chart && currentReport.value !== 'metal-losses'" id="printChart">
        <ReportFilterInfo
          v-if="visibleChartFilter"
          class="mb-30"
          :title="currentReport.name"
          :date="currentFilter.date"
        />

        <BarChart :chartData="chartData" />
      </div>
    </div>

    <h3 v-if="!isLoading && !rows.length">Ничего не найдено</h3>

    <ModalPrint
      v-if="isModal"
      :print-options="printOptions"
      init-print-type="table"
      @request="startPrintReport"
      @reject="closeModal"
    />
  </div>
</template>

<script>
import { ReclamationExternalFilterReports, reclamationExternalFilterReportModel } from '@/features/reclamation-external/filter-reports'
import AppTable from '@/components/ui/AppTable.vue'
import VCheckbox from '@/components/Form/VCheckbox.vue'
import BarChart from '@/shared/ui/Charts/BarChart.vue'
import VButton from '@/components/simple/button/VButton.vue'
import ModalPrint from '@/components/ui/Modal/ModalPrint.vue'
import ReportFilterInfo from '@/components/views/Analytics/ReportFilterInfo.vue'
import {
  reclamationExternalModel,
  ReclamationExternalReportTable
} from '@/entities/reclamation-external'

import { mixinIsLoading } from '@/mixins/mixinIsLoading'
import { mixinModal } from '@/mixins/modal/mixinModal'

import { ReclamationReportApi } from '@/api/reclamation/ReclamationReportApi'
import { ANALYTICS_PRINT_TYPE_LIST } from '@/const/analytics'
import { getHTML2canvasDataURL } from '@/utils/html2canvasLib'
import getValuesArray from '@/utils/array/getValuesArray'
import { getChartDatasets } from '@/utils/chart'
import { mapReportWireConsumptionTableData } from '@/utils/map/mapReportWireConsumptionTableData'
import { showAlertError, showAlertWarning } from '@/utils/store/alert'

export default {
  name: 'ReclamationReportsPage',
  components: {
    ReportFilterInfo,
    ModalPrint,
    VButton,
    BarChart,
    AppTable,
    VCheckbox,
    ReclamationExternalFilterReports,
    ReclamationExternalReportTable
  },

  mixins: [mixinIsLoading, mixinModal],

  data: () => ({
    reportId: 0,
    checkboxes: {
      table: true,
      chart: false
    },

    visibleTableFilter: false,
    rows: [],

    visibleChartFilter: false,
    chartData: {
      labels: [],
      datasets: []
    },

    currentFilter: {},

    isOpenTableTree: false,

    excel: {
      header: [],
      fields: {},
      isLoading: false
    }
  }),

  computed: {
    printOptions () {
      if (!this.currentReport.chart) {
        return ANALYTICS_PRINT_TYPE_LIST.slice(0, 1)
      }
      return ANALYTICS_PRINT_TYPE_LIST
    },

    isReportMetalLoss () {
      return this.reportId === reclamationExternalModel.REPORTS[3].id
    },
    isReportStatistic () {
      return this.reportId === reclamationExternalModel.REPORTS[4].id
    },

    currentReport () {
      return reclamationExternalModel.REPORTS[this.reportId - 1]
    }
  },

  methods: {
    onSubmit (formData) {
      this.setFilter(formData)
      this.fetchReport(formData)
    },

    onReset () {
      this.resetFilter()
      this.resetRows()
      this.setReportId(reclamationExternalModel.REPORTS[0].id)
    },

    setFilter (formData) {
      this.currentFilter.date = formData.date
    },

    resetFilter () {
      this.currentFilter.date = ''
    },

    async fetchReport (formData) {
      try {
        this.startLoading()
        this.resetRows()
        this.setReportId(formData.report_type.id)

        const mappedData = reclamationExternalFilterReportModel.getMapped(formData)
        const response = await ReclamationReportApi.getBy(formData.report_type.value, mappedData)

        this.setRows(response.data.items)

        if (this.currentReport.chart && this.rows.length) {
          this.setChart()
        }
      } catch (e) {
         showAlertError(e)
      } finally {
        this.finishLoading()
      }
    },

    setReportId (id) {
      this.reportId = id
    },

    setRows (data) {
      switch (this.reportId) {
        case reclamationExternalModel.REPORTS[3].id: {
          return this.rows.push(...mapReportWireConsumptionTableData(data))
        }
      }

      this.rows.push(...data)
    },

    resetRows () {
      this.rows.length = 0
    },

    setChart () {
      if (this.currentReport.id === reclamationExternalModel.REPORTS[2].id) {
        this.chartData.labels = getValuesArray(
          this.rows,
          this.currentReport.chart.labels
        ).map(id => reclamationExternalModel.getResolutionNameBy(id))
      } else {
        this.chartData.labels = getValuesArray(
          this.rows,
          this.currentReport.chart.labels
        )
      }

      this.chartData.datasets = getChartDatasets(
        this.rows,
        this.currentReport.chart.datasets
      )
    },

    startPrintReport (data) {
      this.checkPrintDataAndToggle(data)
      this.$nextTick(() => this.printReport(data))
    },

    async printReport (data) {
      try {
        this.$store.commit('START_IS_SENDING_REQUEST_MODAL')

        if (data.type === 'table') {
          this.$htmlToPaper('printTable')
        } else {
          await this.printChart()
        }
      } catch (e) {
        await showAlertError(e)
      } finally {
        this.checkPrintDataAndToggle(data)
        this.$store.commit('FINISH_IS_SENDING_REQUEST_MODAL')
        this.closeModal()
      }
    },

    checkPrintDataAndToggle (data) {
      if (data.type === 'table') {
        this.toggleTableFilter()

        if (this.isReportMetalLoss) {
          this.toggleIsOpenTableTree()
        }
      } else {
        this.toggleChartFilter()
      }
    },

    toggleTableFilter () {
      this.visibleTableFilter = !this.visibleTableFilter
    },
    toggleChartFilter () {
      this.visibleChartFilter = !this.visibleChartFilter
    },

    toggleIsOpenTableTree () {
      this.isOpenTableTree = !this.isOpenTableTree
    },

    async printChart () {
      const src = await this.getCanvasSrc()
      await this.printCanvas(src)
    },

    async getCanvasSrc () {
      try {
        const element = window.document.getElementById('printChart')
        return await getHTML2canvasDataURL(element)
      } catch (e) {
        throw new Error('Не удалось найти получить источник изображения')
      }
    },

    async printCanvas (src) {
      try {
        const win = window.open()
        await win.document.write(`<br><img style="max-width: 100vw" src="${src}"/>`)
        win.print()
      } catch (e) {
        throw new Error('Всплывающее окно заблокировано браузером')
      }
    },

    async getExcelData () {
      try {
        this.excel.isLoading = true
        showAlertWarning('Началась подготовка отчета, пожалуйста, подождите')

        this.setExcelHeader()
        this.setExcelFields()

        if (this.isReportMetalLoss || this.isReportStatistic) {
          const totalItem = {
            [this.currentReport.table.columns[0].name]: 'Итого',
            ...this.$refs.AppTable?.$refs?.total?.totalItem
          }

          return [...this.rows, totalItem]
        }

        return this.currentReport.excel.getMapped(this.rows)
      } catch (e) {
        showAlertError(e)
      } finally {
        this.excel.isLoading = false
      }
    },

    setExcelHeader () {
      this.excel.header.length = 0

      this.excel.header.push(this.currentReport.name, ' ')

      if (this.currentFilter.date) {
        this.excel.header.push(this.currentFilter.date, ' ')
      }
    },

    setExcelFields () {
      this.excel.fields = {}

      this.currentReport.table.columns.forEach(item => {
        this.excel.fields[item.title] = item.name
      })
    }
  }
}
</script>
