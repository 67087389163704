<template>
  <FilterForm
    v-model="formData"
    @request="onSubmit"
    @reset="onReset"
  >
    <VSelect
      label="Тип отчета:"
      name="report_type"
      :options="reportTypeOptions"
      :allow-empty="false"
    />

    <VInputDate
      ref="VInputDate"
      label="Дата:"
      :value="formData.date"
      @value-changed="setDate"
    />
  </FilterForm>
</template>

<script>
import VSelect from '@/components/Form/VSelect.vue'
import FilterForm from '@/components/Form/FilterForm.vue'
import VInputDate from '@/components/Form/VInputDate.vue'

import { mixinFilterEmit } from '@/mixins/form/mixinFilterEmit'
import { reclamationExternalModel } from '@/entities/reclamation-external'

export default {
  components: {
    VInputDate,
    FilterForm,
    VSelect
  },
  mixins: [mixinFilterEmit],
  data: () => ({
    formData: {
      report_type: reclamationExternalModel.REPORTS[0],
      date: ''
    }
  }),
  computed: {
    reportTypeOptions: () => reclamationExternalModel.REPORTS
  },
  methods: {
    onSubmit () {
      this.request(this.formData)
    },

    onReset () {
      this.formData.report_type = reclamationExternalModel.REPORTS[0]
      this.$refs.VInputDate.$refs.dateRef._flatpickr.clear()
      this.reset()
    },

    setDate ({ value }) {
      this.formData.date = value
    }
  }
}
</script>
