import { throwResponseError } from '@/utils/error/throwResponseError'
import { getById } from '@/api/request'

const RECLAMATION_REPORT_URL = 'reclamation/outer/report'

export const ReclamationReportApi = {
  getBy: async (reportType, params) => {
    try {
      return await getById(RECLAMATION_REPORT_URL, reportType, params)
    } catch (e) {
      throwResponseError(e, 'Не удалось загрузить отчет')
    }
  }
}
